import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/Users/radu/code/ebe/src/components/BookLayout.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="p" components={components}>{`Cartea de baza a acestui curs este „Studiul Noului Testament” de Merrill C. Tenney, material de bază ca studiu în foarte multe facultăți și universități creștine.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Mai mult decât o poză frumoasă a fiecărei cărți din care desprindem ușor autorul, scopul și schița cărții, autorul ne prezintă o imagine de ansamblu a Noului Testament în contextul istoric și conexiunile dintre diferite cărți și autorii lor.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Te vei familiariza cu contextul geografic dar și cu folosirea diferitelor unelte ce le vei găsi în curs. Nu poți fi un propovăduitor eficient dacă nu ai parcurs un curs sistematic pe N. Testament. E un material intensiv în 15 lecții care te va solicita dar vei vedea că merită!`}</MDXTag>
      <MDXTag name="p" components={components}>{`Citat: „Creștinismul nu a început să se dezvolte într-un vid religios, în care i-a găsit pe oameni cu sufletul gol, așteptând să li se dea ceva în care să creadă. Dimpotrivă, noua credință în Hristos a trebuit să-și croiască drum luptând împotriva crezurilor ce existau de veacuri.”`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    